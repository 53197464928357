import styled from "styled-components"
import { Container, Row } from "@/components/styled/Grid"
import Icon from "@/components/icons/icon"
import { Instagram } from "@/components/icons/instagram"
import { Whatsapp } from "@/components/icons/whatsapp"
import { Letter } from "@/components/icons/letter"
import { Facebook } from "@/components/icons/facebook"
import Col from "@/components/styled/Grid/Col"

const Background = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`

const Wrapper = styled(Row)`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
`

export default function Social() {
  return (
    <Background>
      <Container>
        <Wrapper>
          <Col>
            <a href="https://facebook.com/physiowow" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <Icon Icon={Facebook} />
            </a>
          </Col>
          <Col>
            <a href="https://instagram.com/physiowow" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <Icon Icon={Instagram} />
            </a>
          </Col>
          <Col>
            <a
              href={`https://wa.me/34649321719?text=${encodeURI("Hola, quiero reservar cita en Physio WOW")}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Whatsapp">
              <Icon Icon={Whatsapp} />
            </a>
          </Col>
          <Col>
            <a href="mailto:physiowow@gmail.com" target="_blank" rel="noopener noreferrer" aria-label="Email">
              <Icon Icon={Letter} />
            </a>
          </Col>
        </Wrapper>
      </Container>
    </Background>
  )
}
